import React from "react";
import ReactDOM from "react-dom/client";
import  App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { AuthContextProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthContextProvider>
            <DarkModeContextProvider>
                <App/>
            </DarkModeContextProvider>
        </AuthContextProvider>
    </React.StrictMode>
);